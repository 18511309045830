<template>
  <div class="page-wrap text-center">
    <div class="session-form-hold">
      <base-card>
        <v-card-text>
           <img
            v-if="$vuetify.theme.dark"
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-light.png"
            alt=""
          />
          <img
            v-else
            class="mb-4"
            height="50"
            src="@/assets/images/logo/logo-dark.png"
            alt=""
          />

          <template v-if="available">
            <h5 class="text-center">Chave de segurança</h5>
            <h6 class="text--disabled text-center font-weight-medium mb-10">
              Clique no botão para cadastrar a chave de segurança
            </h6>

            <template v-if="!success">
              <v-scroll-y-transition v-if="error">
                <v-alert type="error">
                  {{ error }}
                </v-alert>
              </v-scroll-y-transition>

              <v-btn color="primary" block :loading="loading" @click="process">
                Cadastrar
              </v-btn>
            </template>
            <template v-else>
              <v-alert type="success">
                Chave de segurança cadastrada com sucesso!
              </v-alert>
            </template>
          </template>
          <template v-else>
            <v-alert type="error" outlined>
              Seu navegador não suporta a o cadastro de chave de segurança
            </v-alert>
          </template>
          <v-btn class="mt-2" text small block @click="goBack">Voltar</v-btn>
        </v-card-text>
      </base-card>
    </div>
  </div>
</template>
<script>
import ReCaptcha from "@/components/global/ReCaptcha.vue";
import WEBAUTHN from "@/services/account/webauthn";
import { mapActions, mapGetters } from "vuex";
import { startRegistration } from "@simplewebauthn/browser";

export default {
  components: { ReCaptcha },
  name: "WebAuthnRegister",
  metaInfo: {
    title: "Chave de segurança",
  },
  data() {
    return {
      loading: false,
      error: false,
      success: false,
      available: false,
    };
  },
  methods: {
    async process() {
      try {
        this.error = false;
        this.loading = true;
        const { options } = await WEBAUTHN.register.getOptions();
        const credential = await startRegistration(options);

        const response = await WEBAUTHN.register.save(credential);

        this.success = response.success;
      } catch (e) {
        this.error = e.message;
      } finally {
        this.loading = false;
      }
    },
    goBack() {
      this.$router.go(-1) || this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters("auth", ["user"]),
  },
  mounted() {
    this.available = !!window.PublicKeyCredential;
  },
};
</script>
<style lang="scss" scoped>
.page-wrap {
  background: radial-gradient(circle at 50% 50%, rgba(0,0,0,50%) 0%, rgba(0,0,0,90%) 90vw),
    url(/img/logo/auth-background.png), black;
  display: flex;
  background-size: cover;
  background-position: center;
  align-items: center;
  padding: 40px 1rem;
  height: 100%;
  min-height: 100vh;
}
.session-form-hold {
  width: 100%;
  max-width: 400px;
  margin: 0 auto;
}
</style>
