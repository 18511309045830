import http from "../../http-common";

const registerGetOptions = async () => {
  return await http
    .get(`/account/webauthn/register`)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};
const registerSave = async (credential) => {
  return await http
    .post(`/account/webauthn/register`, credential)
    .then((r) => r.data)
    .catch((error) => {
      throw error.response?.data || error.response || error;
    });
};

export default {
  register: {
    getOptions: registerGetOptions,
    save: registerSave,
  },
};
